import firebase from 'firebase/app'

import React, { FunctionComponent } from 'react'

import Loadable from '@loadable/component'

type FirebaseLoadableProps = {
  resolver: (app: firebase.FirebaseApp) => void
  rejecter: (reason: Error) => void
}

const FirebaseLoadable: FunctionComponent<FirebaseLoadableProps> = (props) => {
  const LoadableFirebaseBase = Loadable(
    () => import('../components/firebase-base'),
  )

  return <LoadableFirebaseBase {...props} />
}

export default FirebaseLoadable

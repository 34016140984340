import React, { FunctionComponent } from 'react'

import { Helmet } from 'react-helmet'
import { navigate } from 'gatsby'

import Link from 'gatsby-link'

import firebase from 'firebase/app'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import amber from '@material-ui/core/colors/amber'
import orange from '@material-ui/core/colors/orange'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {
  makeStyles,
  ThemeProvider,
  createTheme,
} from '@material-ui/core/styles'

import FirebaseLoadable from '../components/firebase-loadable'
import DefaultFooter from '../components/default-footer'

// TODO(alex): lazily test for whether we're logged in and replace "sign in"
// with "Go to dashboard / Sign out".

const IndexPage: FunctionComponent = () => {
  let resolver
  let rejecter
  const firebasePromise = new Promise<firebase.FirebaseApp>(
    (resolve, reject) => {
      resolver = resolve
      rejecter = reject
    },
  )
  const [firebaseApp, setFirebaseApp] = React.useState<
    firebase.FirebaseApp | undefined
  >(undefined)

  firebasePromise
    .then((fb: firebase.FirebaseApp) => {
      setFirebaseApp(fb)
    })
    .catch((reason) => {
      console.error(reason)
    })

  const theme = createTheme({
    typography: {
      h1: {
        fontSize: '40.5px',
        fontWeight: 'bold',
      },
    },
    palette: {
      primary: {
        main: orange[500],
      },
      secondary: {
        main: amber['A700'],
      },
    },
  })
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const cssClasses = makeStyles({
    perMonth: {
      fontSize: 10,
    },
  })()

  return (
    <>
      <Helmet
        title="HostBurro"
        meta={[
          { name: 'description', content: 'Sample' },
          { name: 'keywords', content: 'sample, something' },
        ]}>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
        <link rel="preconnect" href="https://firebase.googleapis.com" />
        <link rel="preconnect" href="https://apis.google.com" />
        <link rel="preconnect" href="https://www.googleapis.com" />
        <link rel="preconnect" href="https://identitytoolkit.googleapis.com" />
        <link rel="preconnect" href="https://www.googletagmanager.com" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <div
          style={{
            fontFamily: 'Roboto',
            margin: '0 auto',
            maxWidth: 960,
            padding: '0px 1.0875rem 1.45rem',
            paddingTop: 0,
          }}>
          <Grid container spacing={3}>
            <Grid item xs={12} />
            <Grid item xs={9} md={6}>
              <Typography variant="h1">
                <>
                  <img
                    style={{ display: 'inline', height: '40px', margin: 0 }}
                    src="/assets/images/Icon.png"
                  />{' '}
                  HostBurro{' '}
                </>
              </Typography>
            </Grid>

            <Grid
              item
              xs={3}
              md={6}
              style={{
                textAlign: 'right',
                marginTop: '0.5rem',
              }}>
              <Link
                to="/admin"
                style={{
                  color: 'black',
                  display: 'inline',
                  verticalAlign: 'middle',
                  textDecoration: 'none',
                }}>
                <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>
                  Sign in
                </Typography>
              </Link>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ textAlign: 'center', marginTop: '2rem' }}
            />
            {isSmall ? (
              <>
                <Grid item xs={2} />
                <Grid item xs={8} style={{ textAlign: 'center' }}>
                  <img
                    style={{ width: '100%' }}
                    src="/assets/images/undraw_folder_re_j0i0.svg"
                    alt="Your files, your way"
                  />
                </Grid>
                <Grid item xs={2} />
              </>
            ) : (
              <></>
            )}
            <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
              <Grid
                item
                xs={12}
                style={{
                  textAlign: 'center',
                }}>
                <Typography style={{ fontSize: '2rem' }}>
                  Hassle-free static site hosting for{' '}
                  <Tooltip title="$5 per month">
                    <span>
                      <span>$5</span>
                      <span style={{ fontSize: 13 }}>/</span>
                      <span className={cssClasses.perMonth}>Mo.</span>
                    </span>
                  </Tooltip>
                </Typography>
              </Grid>

              <Grid item container spacing={0}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Typography style={{ marginBottom: 0 }}>
                    Your website, your way;
                  </Typography>
                  <Typography style={{ marginBottom: '1rem' }}>
                    Manage files with{' '}
                    <b>
                      <a
                        href="https://git-scm.com/"
                        style={{
                          color: 'black',
                          textDecoration: 'none',
                        }}>
                        git
                      </a>
                    </b>
                    {', '}
                    <b>
                      <a
                        href="https://dropbox.com/"
                        style={{
                          color: '#0061fe',
                          textDecoration: 'none',
                        }}>
                        Dropbox
                      </a>
                    </b>
                    {', '}
                    (and{' '}
                    <Tooltip
                      title={
                        'like Google Drive, Microsoft OneDrive, ' +
                        'Storj, and many others....'
                      }>
                      <i>more options</i>
                    </Tooltip>{' '}
                    coming soon...)
                  </Typography>
                </Grid>
                {!isSmall ? <Grid item xs={3} /> : <></>}
                <Grid item xs={12} md={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/admin')}
                    fullWidth>
                    Host your site now
                  </Button>
                </Grid>
                {!isSmall ? <Grid item xs={3} /> : <></>}
              </Grid>
            </Grid>
            {!isSmall ? (
              <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
                <img
                  style={{ width: '100%' }}
                  src="/assets/images/undraw_folder_re_j0i0.svg"
                  alt="Your files, your way"
                />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </div>
      </ThemeProvider>
      <FirebaseLoadable resolver={resolver} rejecter={rejecter} />
      <DefaultFooter firebase={firebaseApp} />
    </>
  )
}

export default IndexPage
